
import { defineComponent, onMounted, ref } from "vue";
import FormLoading from "@/components/layout/FormLoading.vue";
import NotaService from "@/services/admin/NotaService";
import { useRoute } from "vue-router";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import router from "@/router";
import store from "@/store";
import Avaliacao from "@/interfaces/Avaliacao";
import BaseService from "@/services/admin/BaseService";

interface Aluno {
  nome: string;
  email: string | null;
  nota: any;
  parametros: any;
}

export default defineComponent({
  components: { FormLoading },
  setup() {
    const $q = useQuasar();
    const route = useRoute();

    const loading = ref<boolean>(false);
    const alunos = ref<Aluno[]>([]);
    const valorCustom = ref<any>([[]]);
    const file = ref<any>();
    const showModal = ref<boolean>(false);
    const avaliacao = ref<Avaliacao>();
    const token = ref<string>();
    const urlApi = ref<string>(process.env.VUE_APP_API_BASE);
    const columns = [
      {
        field: "nome",
        required: true,
        label: "Nome",
        align: "left",
        sortable: true,
      },
      {
        field: "email",
        required: true,
        label: "E-mail",
        align: "left",
        sortable: true,
      },
      {
        name: "imagem",
        align: "center",
        label: "Nota",
        field: "imagem",
        style: "width: 400px",
        sortable: false,
      },
    ];

    const id: string = route.params.id.toString();

    const getAlunos = (): void => {
      NotaService.list(id)
        .then(({ data }) => {
          alunos.value = data.map((data: any) => {
            if (data?.parametros && data?.parametros.length > 0) {
              data.parametros = JSON.parse(data.parametros);
            } else {
              data.parametros = [{ disciplina_id: '', nota: 0 }];
            }
            return data;
          });
        })
        .catch((err) => {
          console.error(err);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        });
    };

    const getAvaliacao = (): void => {
      BaseService.list(`avaliacoes/${route.params.id}`)
        .then(({ data }) => {
          avaliacao.value = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    }

    const salvarNota = (): void => {
      loading.value = true;
      NotaService.save(alunos.value, id)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Notas salva com sucesso",
          });
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao salvar notas",
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const importarNotas = (): void => {
      if (localStorage.getItem("token")) {
        token.value = "Bearer " + localStorage.getItem("token")?.toString();
        showModal.value = true;
      }
    };

    const afterSubmit = (info: any): void => {
      showModal.value = false;
      showNotify({
        type: "positive",
        message: "Anexo enviado com sucesso",
      });
      router.go(0);
    };

    const afterFailed = (): void => {
      showNotify({
        type: "negative",
        message: "Erro ao enviar anexo",
      });
    };

    const changeNota = (valor: string, disciplina_id: string, index_aluno: number, indexDisciplina: number): void => {
      if (alunos.value[index_aluno]) {
        alunos.value[index_aluno].parametros[indexDisciplina] = { 'disciplina_id': disciplina_id, 'valor': valor };
      }
      alunos.value[index_aluno].nota = alunos.value[index_aluno].parametros.reduce((soma: any, i: any) => {
        const total: number = Number(soma.valor) + Number(i.valor);
        return total.toString();
      });
    }
    const isInputDisabled = () => {
      return store.getters.userPerfil === 'professor';
    };

    const getInputValue = (index: number, index2: any) => {
      const aluno = alunos.value[index];
      if (aluno?.parametros?.[index2]?.valor !== undefined) {
        return aluno.parametros[index2].valor;
      }
      return valorCustom.value[index][index2];
    };

    const updateInputValue = (index: number, index2: number, disciplina_id: number, value: any) => {
      const aluno = alunos.value[index];
      if (!aluno.parametros) {
        aluno.parametros = [];
      }
      aluno.parametros[index2] = {
        disciplina_id: disciplina_id,
        valor: value,
      };
      calculateNota(index);
    };

    const calculateNota = (index: number) => {
      const aluno = alunos.value[index];
      if (!aluno.parametros) {
        aluno.nota = 0;
        return;
      }
      aluno.nota = aluno.parametros.reduce((sum: any, param: any) => sum + Number(param.valor.replace(',', '.')), 0);
    };

    onMounted(() => {
      valorCustom.value = [];
      for (let i = 0; i < 400; i++) {
        valorCustom.value[i] = [];
        for (let j = 0; j < 400; j++) {
          valorCustom.value[i][j] = 0;
        }
      }
      getAlunos();
      getAvaliacao();
    });

    return {
      columns,
      showNotify,
      alunos,
      salvarNota,
      loading,
      importarNotas,
      file,
      showModal,
      id,
      afterSubmit,
      afterFailed,
      urlApi,
      token,
      store,
      avaliacao,
      changeNota,
      valorCustom,
      isInputDisabled,
      getInputValue,
      updateInputValue,
      calculateNota,
    };
  },
});
