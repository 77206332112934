/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

const headers = {
  'Content-Type': 'multipart/form-data'
}
class NotaService {
  private endpoint = "notas";

  list(id: string): Promise<any> {
    return http.get(`${this.endpoint}/listar-alunos/${id}`);
  }

  save(data: any, avaliacao_id: string): Promise<any> {
    return http.post(`${this.endpoint}/${avaliacao_id}`, data);
  }

  importar(formData: any, id: string): Promise<any> {
    return http.post(`${this.endpoint}/import/${id}`, formData, { 
      headers: { 
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new NotaService();
